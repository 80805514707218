@import "../../styles/variables.scss";

.collapse-container {
    width: 100%;
    height: auto;
    background-color: transparent;
    @include rowflex(center, center);
    flex-direction: column;
    gap: 1px;
    .collapsible {
        &:not(:last-child) {
            border-bottom: 0px;
        }
    }
}