@import "../../styles/variables.scss";

.singledetail-container {
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    transition: all 0.5s ease;
    @include rowflex(center,center);
    .singledetail-inner{
        width: 100%;
        height: 100%;
        padding: 20px;
        padding-right: 40px;
        padding-left: 40px;
        border-radius:20px ;
        transition: all 0.5s ease;
        @include colflexx(center);
        
        p:nth-child(1){
            margin: 0;
            text-align: center;
            @include fontNormal(1.5em,0em,$wildcard-900);

        }
        p:nth-child(2){
            text-align: center;
            @include fontNormal(1em,1em,$wildcard-900);
        }

        &:hover{
            background-color: #fff;
            p:nth-child(1){
                margin: 0;
                text-align: center;
                @include fontNormal(1.5em,0em,$wildcard-700);
    
            }
            p:nth-child(2){
                text-align: center;
                @include fontNormal(1em,1em,$wildcard-700);
            }
        }

        
    }
}