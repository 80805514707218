@import "../../styles/variables.scss";

.card-container {
    width: 100% !important;
    height: fit-content;
    // flex: 48%;

    @include rowflex(center,center);
    @include breakpoint(tablet) {
       flex: 100%;
    }
    .card-inner-container {
        width: 80%;

        .title {
            margin: 0;
            margin-bottom: 40px;
            @include fontTitleBlack(2.5em, 1em, $wildcard-902);
        }
        .description {
            width: 90%;
            text-align: justify;
            @include fontNormal(1em, 1.5em, $wildcard-902 );
        }
    
        a {
            text-decoration: none;
            margin-top: 20px;
            transition: all 0.5s ease;
            @include fontTitleBold(2em, 1em, $wildcard-700);
    
            @include breakpoint(tablet) {
                margin-top: 10px;
                @include fontTitleBold(1.5em, 1em, $wildcard-700);
            }
    
            @include breakpoint(phablet) {
                @include fontTitleBold(1.2em, 1em, $wildcard-700);
            }
    
            &:hover {
                color: $wildcard-900;
            }
            
        }
    }
   
}