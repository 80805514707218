@import "../../styles/variables.scss";

.contact-container {
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 120px;
    background-color: #FFFAFB;
    @include rowflex(center,center);

    .contact-inner-container {
        width: 100%;
        padding-bottom: 100px;
        @include colflex(center,flex-start);

        .contact-head {
            width: 100%;
            height: 10em;
            background-color: #FFFAFB;
            @include colflex(center, center);

            p:nth-child(1) {
                margin: 0;
                @include fontTitleBlack(3em, 1em, $wildcard-400);
            }
            p:nth-child(2) {
                // margin: 0;
                width: 30%;
                text-align: center;
                @include fontLight(1.1em, 1.5em, $gray-700);
            }
        }
        .contact-details {
            width: 70%;
            height: fit-content;
            @include rowflex(center,center);

     
            @include breakpoint(mobileonly){
                width: 100%;
                @include colflexx(flex-start);
            }
        }

        form {
            width: 50%;
            height: fit-content;
            margin-top: 50px;
            padding: 50px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(157, 157, 157, 0.06);
            border-radius: 30px;
            border-radius: 30px;
            border: 1px solid $wildcard-100;
            @include colflex(center,space-between);

            @include breakpoint(mobileonly){
                width: 80%;
            }

            .form-input {
                margin-bottom: 30px;
            }
            button {
                outline: none;
                border: none;
                width: 100%;
                height: 70px;
                padding-left: 30px;
                padding-right: 30px;
                background-color: $wildcard-700;
                @include fontMedium(1em, 0em, #fff);
                @include rowflex(center,center);
                transition: all 0.5s ease;
                margin-top: 50px;
                @include breakpoint(tablet) {
                    height: 60px;
                }

                &:hover {
                    background-color: $wildcard-902;
                    @include fontMedium(1em, 0em, #fff);
                }
            }

        }
    }
}