@import "../../styles/variables.scss";


.textarea-input {
    width: 100%;
    @include colflexx(flex-start);
    height:fit-content;
    @include breakpoint(mobileonly) {
        @include colflex(flex-start, space-between);
    }
    label {
        height: 20px;
        margin: 0;
        @include fontNormal(1em,1em, $wildcard-901);
        @include breakpoint(mobileonly) {
            @include fontMedium(.8em,1em, $wildcard-901);
        }
    }
    textarea {
        width: 100%;
        height: 150px;
        background-color: $gray-100;
        border: 1px solid $gray-300;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;
        padding-top: 20px;
        padding-left: 20px;
        @include fontNormal(1em, 1em, $black-800);
        resize: none;

        @include breakpoint(mobileonly) {
            width: 100%!important;
            height: 150px;
        }
        @include breakpoint(tablet) {
            width: 100%;
            // height: 50px;
        }

        @include breakpoint(phablet) {
            width: 100%;
            // height: 50px;
        }
        

        &:focus {
            border: 1px solid $wildcard-500;
            background-color: $wildcard-100;
            color: $wildcard-901;
        }
    }
}