@import "../../styles/variables.scss";
.values-container {
    width: 100%;
    margin-top: 50px;
    height: fit-content;
    @include rowflex(center,center);

    .values-inner-container {
        width:80%;
        margin: 0 auto;
        @include rowflex(flex-start,center);
        flex-wrap: wrap;
        gap: 20px;

        @include breakpoint(mobileonly){
            width: 90%;

        }

        @include breakpoint(tablet){
            width: 95%;
        }

        @include breakpoint (laptop){
            width: 90%;
        }

        .single-value {
            width: 350px;
            height: fit-content;
            padding: 20px 20px 20px 20px;
            background-color: #fff;
            border: 1px solid $gray-300;
            border-radius: 10px;
            cursor: pointer;
            @include colflexx(flex-start);


            @include breakpoint(mobileonly){
                width: 400px;
            }

            @include breakpoint(tablet){
                width: 300px;
            }
            
            img{
                width: auto;
            }
            .bottom-content {
                width: 100%;
                margin-top: 20px;
                @include colflexx(flex-start);

                p:nth-child(1){
                    margin: 0;
                    @include fontMedium(1.5em,1em,$wildcard-700);
                }

                p:nth-child(2){
                    margin-top: 5px;
                    @include fontLight(1em, 1.5em, $wildcard-901);
                }
            }
        }
    }
}