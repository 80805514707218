@import "../../styles/variables.scss";

.siem-container {
    width: 100%;
    height: fit-content;
    // position: relative;
    padding-top: 120px;
    margin-bottom: 100px;
    @include rowflex(center,center);
    @include breakpoint(mobileonly){
        margin-bottom: 50px;
    }
    .siem-inner-container {
        width: 100%;
        height: 100%;
        @include colflex(flex-start,flex-start);

        .siem-head {
            width: 100%;
            height: 30em !important;
            background-color: #FFFAFB;
            @include rowflex(center,center);

            p {
                @include fontTitleBlack(3em, 1em, $wildcard-400);

                @include breakpoint(mobileonly){
                    @include fontTitleBlack(2.5em, 1em, $wildcard-400);
                }

            }
        }

        &.bottom {
            width: 90%;
            height: fit-content;
            margin: 0 auto;
            margin-top: 100px;
            gap: 50px;
            @include rowflexx(flex-start);

            @include breakpoint(mobileonly){
                @include colflexx(center);
            }

            .bottom-left {
                width: 100%;
                height: fit-content;
                flex: 2;
                @include colflexx(flex-start);

                @include breakpoint(mobileonly){
                    width: 100%;
                }

                .top {
                    width: 80%;
                    p:nth-child(1){
                        margin: 0;
                        @include fontTitleBlack(1.5em, 1em, $wildcard-700);
                    }
                    p:nth-child(2){
                        margin-top: 10px;
                        @include fontNormal(1.1em, 1.5em, $wildcard-901);
                    }

                    @include breakpoint(mobileonly){
                        width: 100%;
                    }
    
                }
                .overview {
                    margin-top: 30px;
                    @include colflexx(flex-start);

                    .overview-top {
                        width: 70%;
                        p:nth-child(1){
                            margin: 0;
                            @include fontTitleBlack(1.5em, 1em, $wildcard-700);
                        }
                        p:nth-child(2){
                            margin-top: 10px;
                            @include fontNormal(1.1em, 1.5em, $wildcard-901);
                        }

                        @include breakpoint(mobileonly){
                            width: 100%;
                        }
    
                    }

                    ul {
                        list-style: none;
                        padding: 0px;
                        width: 50%;
                        @include breakpoint(mobileonly){
                            width: 100%;
                        }
    
                        li:not(:first-child){
                            margin-top: 20px
                        }

                        li {
                            @include fontNormal(1em, 1.4em, $wildcard-901);
                            @include rowflexx(center);
                            img {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .core {
                    margin-top: 30px;
                    @include colflexx(flex-start);

                    .core-top {
                        width: 100%;
                        p:nth-child(1){
                            margin: 0;
                            @include fontTitleBlack(1.5em, 1em, $wildcard-700);
                        }
                        p:nth-child(2){
                            margin-top: 10px;
                            @include fontNormal(1.1em, 1.5em, $wildcard-901);
                        }
                    }
                    .core-content {
                        display: flex;
                        flex-wrap: wrap;
                        width: 60%;
                        margin-top: 20px;

                        @include breakpoint(mobileonly){
                            width: 90%;
                        }
    
                        .single-core {
                            width: 50%;
                            @include fontNormal(1em, 1.4em, $wildcard-901);
                            @include rowflexx(center);
                            img {
                                width: 40px;
                                margin-right: 10px;
                            }

                            p{
                                @include fontNormal(1em, 1.4em, $wildcard-901);
                            }
                        }
                    }
                }

            }
            .bottom-right {
                background-color: #fff;
                border: 1px solid $gray-300;
                border-radius: 20px;
                // margin-left: 50px;
                width: 100%;
                padding-top: 20px;
                padding-bottom:20px ;
                height: fit-content;
                flex: 1;
                margin: 0 auto;
                @include rowflex(center,center);
            }
        }
    }
}