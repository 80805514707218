@import "../../styles/variables.scss";

.about-container {
    width: 100%;
    height: fit-content;
    // position: relative;
    padding-top: 120px;
    margin-bottom: 100px;
    @include rowflex(center,center);

    @include breakpoint(mobileonly){
        padding-top: 0px;
    }
    @include breakpoint(phablet){
        padding-top: 0px;
    }
    .about-inner-container {
        width: 100%;
        height: 100%;
        @include colflex(flex-start,flex-start);

        .about-head {
            width: 100%;
            height: 20em;
            background-color: #FFFAFB;
            @include rowflex(center,center);

            p {
                @include fontTitleBlack(3em, 1em, $wildcard-400);
            }
        }
    }
}