@import "../../styles/variables.scss";



.form-input{
    @include colflexx(flex-start);
    height:100px;
    width: 100%;
    @include breakpoint(mobileonly) {
        height:100px;
        @include colflex(flex-start, space-between);
    }
    @include breakpoint(phablet) {
        width: 100%!important;
        
    }
    @include breakpoint(tablet) {
        width: 100%!important;
        
    }
    label {
        height: auto;
        @include fontNormal(1em,2em, $wildcard-900);
        @include breakpoint(mobileonly) {
            height: 20px;
            margin-bottom: 5px;
            @include fontMedium(.8em,1em, $wildcard-902);
        }
    }

    
   
    input[type='text'], input[type='password'], input[type='number'], input[type='email'] {
        width: 100%;
        // margin-top: 30px;
        height: 60px;
        // background: #F9F8F9;
        background-color: $gray-100;
        border: 1px solid $gray-300;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;
        padding-left: 20px;
        @include fontNormal(1em, 1em, $gray-500);
        
        @include breakpoint(mobileonly) {
            width: 100%!important;
            height: 50px;
        }
        @include breakpoint(tablet) {
            width: 100% !important;
            height: 50px;
        }

        @include breakpoint(phablet) {
            width: 100% !important; //250PX
            // height: 50px;
        }
        

        &:focus {
            border: 1px solid $wildcard-500;
            background-color: $wildcard-100;
            color: $wildcard-901;
        }
        &:disabled {
            cursor: not-allowed;
        }

        // &:valid {
        //     border: 1px solid $wildcard-500;
        //     background-color: $wildcard-100;
        //     color: $wildcard-901;
        // }

    }
}