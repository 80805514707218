@import "../../styles/variables.scss";

.singlehelp-container {
    width: 300px;
    height: 500px;
    border-radius: 15px;
    background-color: #fff;
    // border :1px solid rgb(233, 233, 233);
    border :1px solid $wildcard-100;
    // box-shadow: 0px 4px 30px rgba(226, 205, 205, 0.2);
    transition: all 0.5s ease;
    @include rowflex(center,center);
    @include breakpoint(phablet){
        width: 90%;
        height: fit-content;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &:hover {
        border :1px solid $wildcard-200;
        box-shadow: 0px 4px 30px rgba(226, 205, 205, 0.2);
    }
    .singlehelp-inner-container {
        width: 100%;
        height: 85%;
        
        @include colflex(center, space-between);

        img {
            width: 80px;
        }

        .content {
            @include colflex(center, normal);
            padding-top: 20px;
            height: 100%;
            p:nth-child(1) {
                margin: 0;
                @include fontTitleBold(1.6em, 1.3em, $wildcard-901);
            }

            p:nth-child(2) {
                text-align: center;
                width: 80%;
                margin: 0;
                @include fontLight(1em, 1.5em, $wildcard-902);

                @include breakpoint(phablet){
                    @include fontLight(1em, 1.7em, $wildcard-902);
                }
            }
        }

        .learn-more {
            height: 30px;
            text-decoration: none;
            margin-top: 20px;
            transition: all 0.5s ease;
            @include fontTitleBold(1.2em, 1em, $wildcard-700);

            &:hover {
                color: $wildcard-900;
            }

            i {
                margin-left: 5px;
            }
        }
    }
}