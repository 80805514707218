@import "../../styles/variables.scss";


.home-container {
    width: 100%;
    height: fit-content;
    padding-top: 50px;
    @include colflexx(center);


    @include breakpoint(mobileonly){
        width: 100%;

        > div:nth-child(odd) {
            background-color: white;
        }

        > div:nth-child(even) {
            background-color: #FFFAFB;

        }
    }
}