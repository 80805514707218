@import "../../styles/variables.scss";

.singlenews-container {
    width: 320px;
    height: 400px;
    background-color: #fff;
    @include rowflex(center,center);
    box-shadow: 0px 4px 30px rgba(148, 90, 90, 0.2);
    position: relative;
    transition: all 0.5s ease;

    @include breakpoint(tablet) {
        width: 220px;
        height: 400px;
    }

    @include breakpoint(mobileonly) {
        width: 350px;
        height: 350px;
    }

    &:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }
    .singlenews-inner-container {
        width: 80%;
        height: 85%;
        @include rowflex(flex-end,center);

        &.top {
            width: 100%;
            height: fit-content;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-bottom: 1px solid $wildcard-100;
            padding-bottom: 30px;

            img {
                width: 320px;
                
                

                @include breakpoint(tablet) {
                    width: 100%;
                }

                @include breakpoint(mobileonly) {
                    width: 100%;
                }
            }
        }

        &.bottom {
            width: 100%;
            height: fit-content;
            position: relative;
            @include colflex(flex-start, space-between);
            p:nth-child(1) {
                width: 100%;
                margin: 0;
                @include fontMedium(0.7em, 0em, $gray-400);

                @include breakpoint(tablet) {
                    @include fontMedium(0.5em, 0em, $gray-400);
                }
            }

            .content {
                width: 100%;
                height: fit-content;
                margin-top: 1.8em;
                p:nth-child(1) {
                    margin: 0;
                    width: 100%;
                    @include fontTitleBlack(1.5em, 1.3em, $wildcard-902);

                    @include breakpoint(tablet) {
                        @include fontTitleBlack(1.2em, 1.2em, $wildcard-902);
                    }

                    @include breakpoint(mobileonly) {
                        @include fontTitleBlack(1em, 1.4em, $wildcard-902);
                    }
                }
                p:nth-child(2) {
                    // margin: 0;
                    @include fontNormal(1.1em,1.5em, $gray-700);

                    @include breakpoint(tablet) {
                        @include fontLight(.8em, 1.5em,$gray-700);
                    }

                    @include breakpoint(mobileonly) {
                        @include fontLight(.8em, 1.7em,$gray-700);
                    }
                }
            }
            a {
                text-decoration: none;
                margin-top: 20px;
                transition: all 0.5s ease;
                @include fontTitleBold(1.5em, 1em, $wildcard-700);
    
                &:hover {
                    color: $wildcard-900;
                }
                
            }
        }
    }
}