@import "../../styles/variables.scss";

.extended-container {
    width: 100%;
    height: auto;
    background-color: #FFFAFB;
    padding-bottom: 100px;
    // background-color: $wildcard-100;
    @include rowflex(flex-end,center);

    .extended-inner-container {
        margin-top: 30px;
        width: 95%;
        height: fit-content;
        @include colflexx(center);

        .lists-container:nth-child(1) p.title {
            color: #338AE8;
        }

        .lists-container:nth-child(2) p.title {
            color: #009C9A;
        }

        .lists-container:nth-child(3) p.title {
            color: #D40039;
        }

        &.top {
            height: fit-content;
            text-align:center;
            
            p:nth-child(1) {
                margin: 0;
                @include fontTitleBlack(2.5em, 1em, $wildcard-902);

                @include breakpoint(mobileonly) {

                    @include fontTitleBlack(2em, 1em, $wildcard-902);
                }
                @include breakpoint(phablet) {

                    @include fontTitleBlack(2em, 1em, $wildcard-902);
                }
            }
            p:nth-child(2) {
                width: 58%;
                @include fontLight(1.1em, 1.2em, $gray-700);

                @include breakpoint(tablet) {
                    width: 80%;
                    @include fontLight(.8em, 1.5em,$gray-700);
                }

                @include breakpoint(mobileonly) {
                    width: 80%;
                    @include fontLight(.8em, 1.2em, $gray-700);
                }
                @include breakpoint(phablet) {
                    width: 80%;

                    @include fontLight(.8em, 1.2em, $gray-700);
                }
            }
        }
        &.bottom {
            width: 100%;
            height: fit-content;
            margin-top: 10px;
            padding-top: 100px;
            @include rowflex(center,center);
            
            @include breakpoint(tablet) {
                margin-top: 40px;
                width: 90%;
            }

            @include breakpoint(mobileonly){
                margin: 0 auto;
                @include colflexx(center);
            }
            @include breakpoint(phablet){
                margin: 0 auto;
                width: 90%;
                @include colflexx(center);
            }

            .lists-container:not(:first-child){
                margin-left: 20px;

                @include breakpoint(mobileonly){
                    margin-left: 0px;
                    margin-top: 20px;
                }
                @include breakpoint(phablet){
                    margin-left: 0px;
                    margin-top: 20px;
                }
            }
        }
    }
}

.block-container {
    background-color: $wildcard-700;
    height:15em;
    width: 100%;
    // margin-bottom: 100px;
    @include rowflex(center,center);
    .block-content {
        @include rowflex(center,center);
        color: white;
        text-align: center;
        width: 50%;

        @include breakpoint(mobileonly){
            width: 80%;
        }
        @include breakpoint(phablet){
            width: 80%;
        }

        p {
            @include fontTitleMedium(1.5em, 1.7em, #fff);

            @include breakpoint(mobileonly){
                @include fontTitleMedium(1.1em, 1.7em, #fff);
            }
            @include breakpoint(phablet){
                @include fontTitleMedium(1.1em, 1.7em, #fff);
            }
        }
    }
}