@import "../../styles/variables.scss";

.lists-container {
    height: auto;
    min-height: 465px;
    gap: 10px;
    width: 30%;
    border: 1px solid $gray-300;
    border-radius: 10px;
    transition: all .5s ease;
    
    &:hover {
        border: 1px solid #FFBECF;
        box-shadow: 0px 4px 30px rgba(148, 90, 90, 0.2);
    }
    @include breakpoint(tablet) {
        width: 100%;
        text-align: center;
    }

     @include breakpoint(mobileonly){
        width: 90% !important;
    }
    @include breakpoint(phablet){
        width: 90% !important;
    }

    .lists-inner-container {
        @include rowflex (center, center);
        flex-wrap: wrap;
        margin: 0 auto;
        width: fit-content;

        
        &.content {
            width: 90%;
            // @include colflex(flex-start, center);
            // margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start !important;
            justify-content: flex-start;
            gap: 30px;
            margin-top: 30px;
            
            // .list-item-container:not(:first-child){
            //     // margin-top: 15px;
            // }
        }
        
    }

    p.title {
        margin: 0;
        @include fontTitleBlack(2.5em, 1em, $wildcard-902);
        margin-bottom: 15px;
        margin-top: 20px;
        text-align: center;

    }
}