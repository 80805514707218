.App {
  width: 100%;
  height: fit-content;
  position: relative;
  // overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
