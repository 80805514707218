@import "../../styles/variables.scss";

.list-item-container {
    width: 200px;

    padding: 0;
    margin: 0;
    height: fit-content;
    @include rowflex (center,center);
    gap: 5px;
    margin-bottom: 10px;

    img {
        width: auto;
        margin-right: 10px;
        
    }

    p{
        margin: 0;
        // width: 75%;
        @include fontNormal(1em, 1.4em, $wildcard-901);
    }
    &.center {
        text-align: center;
        padding-top: 40%;
        padding-bottom: 40%;
        width: 100%;
        @include colflex(center,center);

        img {
            width: auto;
        }

    }
}