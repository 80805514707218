@import "../../styles/variables.scss";

.aboutbanner-container {
    width: 100%;
    height: fit-content;
    @include rowflex(center,center);

    .aboutbanner-inner-container{
        width: 90%;
        height: fit-content;
        @include colflexx(center);

        div{
            margin-top: 50px;
        }
        .about-section {
            width: 70%;
            padding: 10px;
            height: fit-content;
            @include rowflex(flex-start,space-between);

            @include breakpoint(mobileonly){
                width: 90%;
                @include colflexx(flex-start);
            }

            @include breakpoint(phablet){
                width: 90%;
                @include colflexx(flex-start);
            }

            @include breakpoint(tablet){
                width: 90%;
                @include colflexx(flex-start);
            }
            &.left{
                flex: 1;
                height: fit-content;
                padding: 0;
                margin-top: 20px;
                @include colflexx(flex-start);

                img {
                    width: auto;
                }
                p, p:nth-child(2){
                    margin: 0;
                    @include fontTitleBlack(2.5em, 1.4em, $wildcard-902);

                    @include breakpoint(mobileonly){
                        @include fontTitleBlack(2em, 1em, $wildcard-902); 
                    }

                    strong {
                        color: $wildcard-700;
                    }
                }
                p:nth-child(2){
                    margin-top: 10px;
                }

            }

            .subtitle{
                // margin: 0;
                flex: 2;
                text-align: justify;
                @include fontLight(1.3em, 1.7em, $wildcard-901);

                @include breakpoint(mobileonly){
                    @include fontLight(1.1em, 1.7em, $wildcard-901);   
                }
            }
        }

        img {
            width: auto;
            @include breakpoint(mobileonly){
                width: 350px;
            }

            @include breakpoint(phablet){
                width: 350px;
            }
        }

    }
}