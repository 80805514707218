@import "../../styles/variables.scss";

.card-section-container {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    @include rowflex(center,center);

    .card-section-inner-container {
        // @include rowflex (flex-start, space-between);
        // flex-wrap: wrap;
        // gap: 50px;
        width: 90%;

        display: grid;
        grid-template-columns:  1fr 1fr;
        grid-template-rows: 1fr 1fr;

        @include breakpoint(mobileonly){
            width: 100% !important;
            @include colflexx(center);
        }
        @include breakpoint(phablet){
            width: 100% !important;
            @include colflexx(center);
        }
    }
}