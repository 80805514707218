@import "../../styles/variables.scss";

.consultation-container {
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    padding-top: 50px;
    background-color: transparent;
    // background-color: $wildcard-100;
    @include rowflex(flex-end,center);

    .consultation-inner-container{
        width: 90%;
        height: fit-content;
        @include colflexx(center);

        &.top {
            height: fit-content;
            text-align:center;
            p:nth-child(1){
                margin: 0;
                @include fontTitleBlack(2.5em, 1em, $wildcard-902);

                @include breakpoint(phablet){
                    @include fontTitleBlack(2em, 1em, $wildcard-902);
                }
                @include breakpoint(mobileonly){
                    @include fontTitleBlack(2em, 1em, $wildcard-902);
                }
            }
            p:nth-child(2){
                width: 58%;
                @include fontLight(1.1em, 1.2em, $gray-700);

                @include breakpoint(tablet) {
                    width: 80%;
                    @include fontLight(.8em, 1.5em,$gray-700);
                }
                @include breakpoint(phablet){
                    width: 90%;
                    @include fontLight(.8em, 1.2em, $gray-700);
                }
                @include breakpoint(mobileonly){
                    width: 90%;
                    @include fontLight(.8em, 1.2em, $gray-700);
                }
            }
        }
        &.bottom {
            width: 100%;
            height: fit-content;
            margin-top: 10px;
            @include rowflex(center, space-between);
            
            @include breakpoint(tablet) {
                margin-top: 40px;
                width: 90%;
            }
            @include breakpoint(phablet) {
                margin-top: 40px;
                width: 100%;
            }
        }
    }
}