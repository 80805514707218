@import "../../styles/variables.scss";

.collapsible {
    border-width: 1px 0;
    border-style: solid;
    border-color: $wildcard-100;
    width: 70%;
    font-size: 1.5em;

    @include breakpoint(mobileonly){
        width: 80%;
        @include fontNormal(1.1em, 1.2em, $gray-700);
    }
    @include breakpoint(phablet){
        width: 80%;
        @include fontNormal(1.1em, 1.2em, $gray-700);
    }

    .collapse-block {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 0.5em 0;
        padding-right: 0px;
        cursor: pointer;
        
        @include breakpoint(mobileonly){
            padding: 1em 0;
        }
        @include breakpoint(phablet){
            padding: 1em 0;
        }

        
        &.toggle-expand > div{
            height: 1em;
            width: 1em;
            cursor: pointer;
            background-image: url("../../assets/collapse.svg");
        }

        &.toggle-collapse > div {
            height: 1em;
            width: 1em;
            cursor: pointer;
            background-image: url("../../assets/expand.svg");
        }
    }

    .content {
        &.hide {
            display: none;
        } 
        
        ul {
            list-style: none;
            padding: 0;
            @include breakpoint(mobileonly){
                height: fit-content;
                // width: 100%;
                li:not(:first-child){
                    margin-top: 10px;
                }
            }
            @include breakpoint(phablet){
                height: fit-content;
                // width: 100%;
                li:not(:first-child){
                    margin-top: 10px;
                }
            }
           li {
               position: relative;
               height: 3em;
               font-size: 14px;
               margin-left: 30px;

                @include breakpoint(mobileonly){
                    height: fit-content;
                    @include fontLight(.8em, 1.8em, $gray-700);
                }
                @include breakpoint(phablet){
                    height: fit-content;
                    @include fontLight(.8em, 1.8em, $gray-700);
                }

               &:before {
                content: "\f621";
                font-family: FontAwesome;
                display: table;
                position: absolute;
                margin-left: -30px;
                color: $wildcard-500;
                font-size: 18px;

                @include breakpoint(mobileonly){
                    font-size: 10px;
                    margin-left: -20px;
                }
                @include breakpoint(phablet){
                    font-size: 10px;
                    margin-left: -20px;
                }

                
               }

           }
        }
    }
}