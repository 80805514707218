@import "../../styles/variables.scss";

.explore-container {
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    
    background-color: transparent;
    @include rowflex(center,center);


    @include breakpoint(mobileonly) {
       height: fit-content;
    //    overflow: hidden !important;
    }
    @include breakpoint(phablet) {
        height: fit-content;
     }

    


    .explore-inner-container {
        width: 90%;
        height: fit-content;
        @include colflexx(center);

        @include breakpoint(tablet) {
            width: 95%;
        }

        p:nth-child(1) {
            margin: 0;
            @include fontTitleBlack(2.5em, 1em, $wildcard-902);
        }

        &.bottom {
            width: fit-content;
            height: fit-content;
            margin-top: 70px;
            @include rowflex(center, space-between);

            .chevron-left, .chevron-right {
                width: 60px;
                height: 60px;
                background-color: $gray-400;
                transition: all 0.5s ease;
                @include rowflex(center,center);
                
                &:hover {
                    background-color: $gray-600;
                    box-shadow: 0px 4px 20px rgba(214, 214, 214, 0.25);
                }

                &.hide {
                    opacity: 0;
                }

                @include breakpoint(tablet) {
                    width: 30px;
                    height: 30px;
                }

                img {
                    width: auto;

                    @include breakpoint(tablet) {
                        width: 10px;
                    }
                }
            }

            .singlenews-container:not(:first-child){
                margin-left: 30px;

                @include breakpoint(mobileonly){
                    margin-left: 0px;
                    margin-top: 20px;
                }
            }

            @include breakpoint(mobileonly){

                @include colflexx(center);
                div:not(:first-child){
                    margin-top: 10px;
                }
            }
            @include breakpoint(phablet){

                @include colflexx(center);
                div:not(:first-child){
                    margin-top: 20px;
                }
            }
        }
    }
}