@import "../../styles/variables.scss";

.product-container {
    width: 100%;
    height: 65em;
    background-color: #FFFAFB;
    @include rowflex(center,center);

    .primary {
        color: $wildcard-700;
    }

    .secondary {
        color: $wildcard-902;
        opacity: 0.3;
    }


    @include breakpoint(mobileonly){
        height: fit-content !important;
        width: 100%;
    }
    @include breakpoint(phablet){
        padding-top: 30px;
        height: fit-content !important;
        width: 100%;

        padding-bottom: 50px;

    }

    .product-inner-container {
        width: 100%;
        height: 85%;
        @include colflex(center, space-between);

        @include breakpoint(tablet) {
            width: 80%;
        }
        @include breakpoint(phablet) {
            width: 90%;
            height: 100%;
            @include colflexx(center);
        }

        @include breakpoint(mobileonly){
            width: 100%;
            height: 90%;

            @include colflexx(center);
        }

        .dim {
            opacity: 0.5;
        }

        hr {
            margin-right: 30px;
            margin-left: 30px;
            background: grey;
            height: 2px;
            border: none;
        }

        p {
            // &.info {
            //     font-weight: 400;
            //     font-size: 1.5em;
            //     padding-bottom: 10px;
            // }
            // margin: 0;
            @include fontTitleBold(3em, 0em, $wildcard-902);

            @include breakpoint(tablet) {
                @include fontTitleBold(2.5em, 0em, $wildcard-902);
            }

            @include breakpoint(phablet) {
                @include fontTitleBold(2em, 0em, $wildcard-902);
            }

            @include breakpoint(mobileonly){
                // width: 100%;
                @include fontTitleBold(2em, 1em, $wildcard-902);
            }
        }
        &.top {
            
            height: fit-content;

           
            p:nth-child(1) {
                margin: 0;
                @include fontTitleBlack(2.5em, 1em, $wildcard-902);

                @include breakpoint(mobileonly){
                    @include fontTitleBlack(2em, 1em, $wildcard-902);
                }
                @include breakpoint(phablet){
                    @include fontTitleBlack(2em, 1em, $wildcard-902);
                }
            }
            p:nth-child(2) {
                width: 50%;
                text-align: center;
                @include fontLight(1.1em, 1.2em, $gray-700);

                @include breakpoint(mobileonly){
                    width: 90%;
                }
                @include breakpoint(phablet){
                    width: 90%;
                }
            }
        }
        
        
        
        .product-offer {

            width: 25%;
            @include colflex(center, space-between);

            @include breakpoint(mobileonly){
                width: 90%;
                @include rowflexx(flex-start);
                button:not(:first-child){
                    margin-left: 10px;
                }

                @include breakpoint(mobileonly){
                    @include colflexx(center);
                }
            }
            @include breakpoint(phablet){
                width: 90%;

                // @include rowflexx(flex-start);
                @include colflexx(center);
                button:not(:first-child){
                    margin-left: 10px;
                }

            }

            &.top {
                width: 100%;
                @include rowflex(center,space-between);
                height: fit-content;
                z-index: 5;

                @include breakpoint(mobileonly){
                    margin-top: 50px;
                    width: 80%;
                }
                @include breakpoint(phablet){
                    margin-top: 50px;
                    width: 80%;
                }
                
                p {
                    height: 50px;
                    margin: 0;
                    cursor: pointer;
                    @include fontTitleBold(2em, 0em, $wildcard-700);

                    @include breakpoint(mobileonly){
                        @include fontTitleBlack(1.5em, 1em, $wildcard-902);
                    }
                    

                    &.active {
                        color: $wildcard-700;
                        border-bottom: 5px solid $wildcard-700;
                    }

                    &.disable{
                        color: $wildcard-300;
                    }
                }
            }
            hr {
                width: 100%;
                background: $wildcard-200;
                height: 5px;
                border: none;

                margin: -5px;

                @include breakpoint(mobileonly){
                    width: 80%;
                }
    

            }
            
            button {
                background-color: #D40039;
                color: white;
                border: none;
                padding: 12px 16px;
                font-size: 16px;
                cursor: pointer;
                width: 200px;
                height: 45px;
                border-radius: 13px;
                box-shadow: 0px 0px 7px black;

                @include breakpoint(mobileonly){
                    width: 270px !important;
                    @include fontNormal(1em, 1em, #fff);
                    box-shadow: none;
                }

            width: 30%;
            @include colflexx(center);

            
            
            }
    
            .add-animation {
                -webkit-animation-duration: 1s;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-name: slide-in;
            }

            
        }
        .product-card-container {
            width: 80%;
            margin: 0 auto;
            height: fit-content;
            min-height: 500px;
            margin-top: 30px;
            
            @include rowflex(normal, space-between);
            
            @include breakpoint(mobileonly){
                width: 90%;
                height: fit-content;

                @include colflexx(flex-start);
            }
            @include breakpoint(phablet){
                width: 90%;
                height: fit-content;
                @include colflexx(flex-start);
            }
            .left-card {
                margin-right: 10px;
                flex: 1 1 0;
                @include breakpoint(mobileonly){
                    width: 100% !important;
                    margin-bottom: 50px !important;
                }
                @include breakpoint(phablet){
                    width: 100% !important;
                    margin-bottom: 50px !important;
                }
                div.title {
                    // font-size: 20px;
                    // margin-top: 6px;
                    // margin-bottom: 10px;
                    // margin-left: 20px;
                    margin-left: 0px;
                    padding:20px;
                    display: inline-block;


                    @include breakpoint (mobileonly){
                        margin-top: 10px;
                        @include fontTitleBold(1.5em, 1em, $wildcard-700);
                    }

                    @include fontTitleMedium(1.5em, 1em, $wildcard-700);

                }
                .small-info-box {
                    border-radius: 5px;
                    border-color: white;
                    background-color: white;
                    height: 100px;
                    padding:10px;
                    text-align: center;
                    @include fontNormal(1.2em, 1.4em, $wildcard-902);
                    // font-size: 20px;

                    hr {
                        background: $wildcard-100;
                        height: 2px;
                        border: none;
                    }
                    
                    
                    div:first-child {
                        padding-top: 10px;
                    }

                    div:nth-child(2) {
                        padding-top: 25px;
                    }

                    @include breakpoint(mobileonly){
                        padding: 0 !important;
                        margin-top: -20px;
                        @include fontNormal(1em, 1.4em, #fff);

                    }
                }
                .big-info-box {
                    border-radius: 5px;
                    border-color: white;
                    background-color: white;
                    height: auto;
                    margin-bottom: 50px;

                    @include breakpoint (mobileonly){
                        width: 100% !important;
                    }
                    .description {
                        margin-right: 20px;
                        margin-left: 20px;
                        padding-bottom: 20px;
                        line-height: 25px;
                        text-align: justify;


                        @include breakpoint (mobileonly){
                            @include fontNormal(.8em, 1.4em, $wildcard-902);
                        }

                        @include fontNormal(1em, 1.4em, $wildcard-902);

                    }
                }
            }

            .right-card {
                color: white;
                // background-color: ghostwhite;
                background-color: #342131;
                border-color: white;
                border-radius: 10px;
                height: 550px;
                min-height: 400px;
                flex: 3 1 0;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                flex-direction: column;


                @include breakpoint(mobileonly){
                    width: 100% !important;
                    height: fit-content;
                    padding: 30px 0px 30px 0px;
                    .info-container:not(:first-child){
                        margin-top: 30px !important;
                    }
                }
                @include breakpoint(phablet){
                    width: 100% !important;
                    height: fit-content;
                    padding: 30px 0px 30px 0px;
                    .info-container:not(:first-child){
                        margin-top: 30px !important;
                    }
                }



                

                .info-container {
                    width: 360px;
                    height: 50px;
                    margin: 20px;
                    transform: translate(0px, 0px) rotate(0deg) scale(1, 1);
                    transform-origin: center center;
                    @include rowflex(normal, space-between);

                    @include breakpoint (mobileonly){
                        display: flex;
                        margin: 0px !important;
                        align-items:center !important;
                        justify-content: center !important;
                    }
                    @include breakpoint (phablet){
                        display: flex;
                        margin: 0px !important;
                        align-items:center !important;
                        justify-content: center !important;
                    }

                    div.card {
                        height: 50px;
                        width: 300px;
                        border-radius: 10px;
                        background-color: white;
                        text-align: center;

                        .card-title {
                            height: 50px;

                            line-height: 50px;

                            @include breakpoint (mobileonly){
                                margin: 0;
                                @include rowflex(center,center);
                                @include fontTitleBold(1em , 1em, $wildcard-900);

                            }
                        }

                        @include breakpoint (mobileonly){
                            width: 200px;
                            height: 50px;
                            padding: 0px 20px 0px 20px;
                            @include rowflex(center,center);

                            // line-height: 50px;
                            @include rowflex(center,center);
                            @include fontNormal(.9em, 0em, #342131);
                            // color: #342131 !important;
                        }
                        @include breakpoint (phablet){
                            width: 200px;
                            height: 50px;
                            padding: 0px 20px 0px 20px;
                            @include rowflex(center,center);
                            @include rowflex(center,center);
                            @include fontNormal(.9em, 0em, #342131);
                        }

                        &.disable {
                            opacity: 0.3;

                        }
        
                    }

                    div.circle-card-container {

                        @include breakpoint(mobileonly){
                            margin-left: 20px;
                        }
                        @include breakpoint(phablet){
                            margin-left: 20px;
                        }
                        div.circle-card {
                            height: 50px;
                            width: 50px;
                            background-color: white;
                            border-radius: 50%;
                            display: inline-block;
                            text-align: center;
                            line-height: 50px;
                            color: $wildcard-700;
                        }

                    }
                }
            }
        }
    }

}