@import "../../styles/variables.scss";

.navbar-container {
    width: 100%;
    height: 120px;
    background-color: #fff;
    position: fixed;
    z-index: 10;
    
    @include rowflex(center,center);
    background: rgba(255, 255, 255, 0.45);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    .navbar-inner-container{
        width: 90%;
        height: 90%;
        @include rowflex(center, space-between);

        &.left {
            width: 250px;
            height: 50px;

            img {
                width: 150px;
            }
        }

        &.right {
            width: 50%;
            
            @include rowflex(center, space-around);


            @include breakpoint( phablet){
                display: none;
            }
            @include breakpoint(mobileonly){
                display: none;
            }
            .nav-link {  
                p:not(:first-child) {
                    margin-left:20px;
                }
            }

            .nav-link {

                @include fontNormal(1em, 1em, $wildcard-902);
                position: relative;
                cursor: pointer;
                transition: all 1s ease;
                &:hover {
                    @include fontMedium(1em, 1em, $wildcard-700);
                    &::after{
                        position: absolute;
                        bottom: -20px;
                        left: 40%;
                        content: " ";
                        width: 10px;
                        height: 10px;
                        border-radius: 50px;
                        background-color: $wildcard-700;
                        transition: all 1s ease;
                    }
                }
                .active, &.active {
                    @include fontMedium(1em, 1em, $wildcard-700);
                    &::after{
                        position: absolute;
                        bottom: -20px;
                        left: 40%;
                        content: " ";
                        width: 10px;
                        height: 10px;
                        border-radius: 50px;
                        background-color: $wildcard-700;
                        transition: all 1s ease;
                    }
                }

                i {
                    margin-left: 10px;
                }

                .box {
                    width: 300px;
                    height: 100px;
                    background-color: #fff;
                    filter: drop-shadow(0px 0px 10px rgba(183, 225, 255, 0.15));
                    border: 1px solid $gray-300;
                    position: absolute;
                    top: 30px;
                    left: -100%;
                    border-radius: 10px;

                    @include rowflex(center,center);
                    
                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                    &.disable {
                        visibility: hidden;
                    }
                    
                    .box-inner {
                        width: 90%;
                        height: 80%;

                        @include rowflexx(flex-start);

                        img {
                            width: auto;
                            margin-right: 10px;
                        }
                        .right {
                            @include colflexx(flex-start);
                            padding: 0;
                            width: fit-content;
                            p:nth-child(1) {
                                margin: 0;
                                @include fontTitleBold(1.25em, 1em, $wildcard-900);
                            }
                            p:nth-child(2) {
                                margin-left: 0 !important;
                                width: 100%;
                                @include fontLight(.88em, .5em, $gray-500);
                            }
                        }
                    }      
                }       
            }

            .services {
                width: fit-content;
                height: fit-content;
                position: relative;
                .nav-link {
                    @include fontNormal(1em, 1em, $wildcard-902);
                    // position: relative;
                    cursor: pointer;
                    transition: all 1s ease;
                    &:hover {
                        @include fontMedium(1em, 1em, $wildcard-700);
                    }
                    .active, &.active {
                        @include fontMedium(1em, 1em, $wildcard-700);
                    }
    
                    i {
                        margin-left: 10px;
                    }               
                }
                .box {
                    width: 250px;
                    height: auto;
                    background-color: #fff;
                    filter: drop-shadow(0px 0px 10px rgba(183, 225, 255, 0.15));
                    border: 1px solid $gray-300;
                    position: absolute;
                    top: 30px;
                    left: -100%;
                    border-radius: 10px;

                    @include rowflex(center, center);
                    
                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                    &.disable {
                        display: none;
                        visibility: hidden;
                    }  
                    .box-inner {
                        width: 100%;
                        height: 80%;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: all 0.5s ease;
                        @include colflexx(center);
                        
                        .sub-menu:hover {
                            color: white;
                            background-color: $wildcard-700;

                            > a {
                                color: white;
                            }
                        }

                        .sub-menu {
                            width: 100%;
                            text-align: center;
                            line-height: 40px;

                            > a {
                                color: $wildcard-700;
                            }

                            &.active {
                                background-color: $wildcard-900;
                                > a {
                                    color: white;
                                }
                            }
                        }

                        img {
                            width: auto;
                            margin-right: 10px;
                        }
                        .right {
                            @include colflexx(flex-start);
                            padding: 0;
                            width: fit-content;
                            p:nth-child(1) {
                                margin: 0;
                                @include fontTitleMedium(1.25em, 1em, $wildcard-900);
                            }
                            p:nth-child(2) {
                                margin-left: 0 !important;
                                width: 100%;
                                @include fontLight(.88em, .5em, $gray-500);
                            }
                        }
                         &:hover {
                             
                             p:nth-child(1) {
                                margin: 0;
                                @include fontTitleMedium(1.25em, 1em, $wildcard-700);
                            }
                         }
                    }

                    
                }
            }

        }

        .Menu-toggle {
            display: none;

            @include breakpoint(mobileonly){
                @include fontBold(1.2em, 1.2em, $wildcard-902);
                display: flex;
                height: 10px;
            }
            @include breakpoint(phablet){
                @include fontBold(1.2em, 1.2em, $wildcard-902);
                display: flex;
                height: 10px;
            }
            @include breakpoint(tablet){
                @include fontBold(1.2em, 1.2em, $wildcard-902);
                display: flex;
                height: 10px;
            }
        }

    }

    .menu-responsive-container {
        position: absolute;
        top: 120px;
        left: 0;
        right: 0;
        width: 80%;
        height: fit-content;
        padding: 30px;
        border-radius: 30px;
        margin: 0 auto;
        z-index: 100;
        border: 1px solid $gray-300;
        background-color: #fff;
        opacity: 0;
        transition:  all .5s ease-out;
        @include rowflex(center,center);

        &.active {
            display: flex;
            opacity: 1;
            filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
        }

        &.disabled {
            display: none;

        }
        
        .menu-responsive-inner {
            width: 90%;
            @include colflexx(center);

            .nav-link {

                @include fontNormal(1.5em, 1em, $wildcard-902);
                position: relative;
                cursor: pointer;
                transition: all 1s ease;
                &:hover {
                    @include fontMedium(1.5em, 1em, $wildcard-700);
                   
                }
                .active, &.active {
                    @include fontMedium(1.5em, 1em, $wildcard-700);
                    
                }

                i {
                    margin-left: 10px;
                }

                .box {
                    width: 300px;
                    height: 100px;
                    background-color: #fff;
                    filter: drop-shadow(0px 0px 10px rgba(183, 225, 255, 0.15));
                    border: 1px solid $gray-300;
                    position: absolute;
                    top: 30px;
                    left: -100%;
                    border-radius: 10px;

                    @include rowflex(center,center);
                    
                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                    &.disable {
                        visibility: hidden;
                    }
                    
                    .box-inner {
                        width: 90%;
                        height: 80%;

                        @include rowflexx(flex-start);

                        img {
                            width: auto;
                            margin-right: 10px;
                        }
                        .right {
                            @include colflexx(flex-start);
                            padding: 0;
                            width: fit-content;
                            p:nth-child(1) {
                                margin: 0;
                                @include fontTitleBold(1.25em, 1em, $wildcard-900);
                            }
                            p:nth-child(2) {
                                margin-left: 0 !important;
                                width: 100%;
                                @include fontLight(.88em, .5em, $gray-500);
                            }
                        }
                    }      
                }
            }

            .nav-link{
                margin-top: 30px;
            }  

            .services {
                width: fit-content;
                height: fit-content;
                position: relative;
                @include colflexx(center);
                .nav-link {
                    @include fontNormal(1.5em, 1em, $wildcard-902);
                    // position: relative;
                    cursor: pointer;
                    transition: all 1s ease;
                    
                    .active, &.active {
                        color: $wildcard-902;
                        
                    }
    
                    i {
                        margin-left: 10px;
                    }  
                }
                .box {
                   
                    display: flex;
                    @include rowflex(center,center);
                    
                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                    &.disable {
                        display: none;
                        visibility: hidden;
                    }
                    
                    .box-inner {
                        width: fit-content;
                        height: fit-content;
                        padding: 20px;
                        cursor: pointer;
                        transition: all 0.5s ease;

                        @include colflexx(center);

                        .sub-menu:hover {
                            color: white;
                            background-color: $wildcard-700;

                            > a {
                                color: white;
                            }
                        }

                        .sub-menu {
                            width: 100%;
                            text-align: center;
                            line-height: 40px;

                            > a {
                                color: $wildcard-700;
                            }

                            &.active {
                                background-color: $wildcard-900;
                                > a {
                                    color: white;
                                }
                            }
                        }

                        img {
                            width: 40px;
                            margin-right: 10px;
                        }
                        .nav-link {
                            margin: 0;
                            @include fontTitleMedium(1em, 1.4em, $wildcard-900);
                        }
                    }
                }
            }
            
        }
    }
}

