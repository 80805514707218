@import "../../styles/variables.scss";

.services-container {
    width: 100%;
    height: auto;
    background-color: #FFFAFB;
    @include rowflex(center,center);


    @include breakpoint(phablet) {
        height: 80em;

       
    }


    .services-inner-container {
        width: 80%;
        padding-top: 100px;
        padding-bottom: 100px;
        @include colflex (center, space-between);

        p:nth-child(1) {
            margin: 0;
            margin-bottom: 40px;
            @include fontTitleBlack(2.5em, 1em, $wildcard-902);

        }
        
        div:not(:first-of-type) {
            margin-top: 50px;
        }

        @include breakpoint(mobileonly) {
            height: fit-content;
            @include colflex(center,center);
            @include colflexx(center);
            
            p:nth-child(1) {
                margin-bottom: 0px;
            }   
        }
        
        @include breakpoint(phablet) {
            height: fit-content;
            @include colflex(center,center);
            @include colflexx(center);
            
            p:nth-child(1) {
                margin-bottom: 0px;
            }   
        }
    }
}