@import "../../styles/variables.scss";

.title-bar {
    background-color: $wildcard-700;
    .title-success {
        margin-left: 20px;
        color: white;
    }
}

.disabled {
    opacity: 0.5;
}

form {
    width: 90%;
    height: fit-content;
    @include colflex(center,space-between);

    .form-input {
        margin-bottom: 30px;
    }

    button {
        outline: none;
        border: none;
        width: 100%;
        height: 70px;
        padding-left: 30px;
        padding-right: 30px;
        background-color: $wildcard-700;
        @include fontMedium(1em, 0em, #fff);
        @include rowflex(center,center);
        transition: all 0.5s ease;
        margin-top: 50px;
        @include breakpoint(tablet) {
            height: 60px;
        }

        &:hover {
            background-color: $wildcard-902;
            @include fontMedium(1em, 0em, #fff);
        }
    }

}