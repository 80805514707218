@import "../../styles/variables.scss";

.footer-container {
    width: 100%;
    height: 20em;
    background-color: $wildcard-700;
    @include rowflex(center,center);

    @include breakpoint (mobileonly){
        height: 50em;
    }
    @include breakpoint (tablet){
        height: 30em;
    }

    @include breakpoint (phablet){
        height: 50em;
    }

    .footer-inner-container {
        width: 85%;
        height: 80%;

        @include colflex(flex-start, space-between);
        @include breakpoint(tablet) {
            width: 90%;

        }
        
        img {
            width: 200px;
            @include breakpoint(tablet) {
                width: 200px;
            }
        }

        &.top {
            width: 100%;
            height: fit-content;
            @include rowflex(flex-start, space-between);

            @include breakpoint(mobileonly) {
                width: 100%;
                @include colflexx(flex-start);
            }

            @include breakpoint(phablet) {
                width: 100%;
                @include colflexx(flex-start);
            }

            .left {
                height: fit-content;
                @include colflex(flex-start, space-between);
                @include breakpoint(phablet){
                    @include colflexx(flex-start);
                    margin-top: -70px;
                }
                flex: 2 1;
                .input-content{
                    width: fit-content;
                    height: fit-content;
                    margin-top: 20px;
                    @include colflex(flex-start, space-between);
                    p {   
                        width: 70%;
                        @include fontLight(.87em, 2em, #fff);

                        @include breakpoint(mobileonly){
                            width: 90%;
                        }
                        @include breakpoint(phablet){
                            margin: 0;
                            width: 90%;
                        }
                    }
                    form {
                        width: fit-content;
                        margin-top: 10px;
                        input[type='text'] {
                            width: 450px;
                            height: 70px;
                            border: none;
                            padding: 0;
                            margin: 0;
                            box-sizing: border-box;
                            outline: none;
                            padding-left: 20px;
                            @include fontNormal(1em, 1em, $gray-600);

                            @include breakpoint(tablet) {
                                width: 350px;
                            }
                        }
                    }

                    p:nth-child(4) {
                        width: 60%;
                        @include fontLight(.75em, 1.5em, #fff);
                        @include breakpoint(tablet) {
                            width: 80%;
                            @include fontLight(.7em, 1.5em, #fff);
                        }
                    }

                }
            }

            .right {
                height: fit-content;
                @include colflex(flex-start, space-between);
                flex: 1 1;
                @include breakpoint(tablet) {
                    width: 70%;
                }

                @include breakpoint(mobileonly){
                    margin-top: 30px;
                    width: 90%;
                }
                @include breakpoint(phablet){
                    margin-top: 30px;
                    width: 90%;
                }

                .top-links, .bottom-links {
                    width: 100%;
                    height: fit-content;
                    @include rowflex(flex-start, space-between);

                    ul {
                        list-style: none;
                        padding: 0px;
                        margin: 0px;
                    }
                    
    
                    .company-footer-links, .products-footer-links, .resources-footer-links  {
    
                        li {
                            @include fontNormal(0.9em, 2em, #fff);
                            cursor: pointer;
                            transition: all .3s ease-in;
    
                            @include breakpoint(mobileonly) {
                                @include fontNormal(0.8em, 2em, #fff);
                            }

                            @include breakpoint(tablet) {
                                @include fontNormal(0.8em, 2em, #fff);
                            }
    
                            &:hover {
                                color: $wildcard-900;
                            }
                            
                            a {
                                text-decoration: none;
                                color: inherit;
                            }
                        }
                        li:nth-child(1) {
                            margin-bottom: 10px;
                            @include fontTitleBlack(1.2em, 1em, #fff);
                            cursor:auto;
                        }
                    }
                }
                .bottom-links{
                    margin-top: 50px;
                }

            }
        }

        hr{
            width: 100%;
            margin-bottom: 0 auto;
            border: .5px solid $wildcard-800;
        }

        &.bottom {
            width: 100%;
            height: fit-content;
            @include rowflex(center, space-between);

            p{
                @include fontNormal(1.1em, .5em, #fff);

                @include breakpoint(tablet) {
                    @include fontNormal(.8em, .5em, #fff);
                }

                @include breakpoint(mobileonly) {
                    margin: 0;
                    @include fontNormal(1em,1.4em, #fff);
                }
            }
        }
    }
}