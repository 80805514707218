@import "../../styles/variables.scss";

.banner-container {
    width: 100%;
    height: 45em;
    background-color: #fff;
    @include rowflex(center, center);

    @include breakpoint(phablet) {
        height: 40em;
    }

    @include breakpoint(mobileonly) {
        padding-top: 100px;
        height: 25em;
    }

    .banner-inner-container{
        width: 90%;
        @include rowflex(center,space-between);

        @include breakpoint(mobileonly) {
            @include colflexx(flex-start);
        }
        @include breakpoint(phablet) {
            @include colflexx(flex-start);
        }

        &.left {
            width: 50%;
            height: fit-content;
            @include colflex(flex-start, space-between);
            @include breakpoint(tablet) {
                width: 80%;
            }
            @include breakpoint(phablet) {
                width: 100%;
            }
            @include breakpoint(mobileonly) {
               width: 100%;

            }

            .top {
                p:nth-child(1) {
                    margin: 0;
                    // background-color: red;
                    display: -webkit-box;
                    overflow: hidden;
                    max-height: 2.7em;
                    -webkit-box-orient: vertical;
                     -webkit-line-clamp: 2;
                     width: 70%;
                     
                     @include breakpoint(mobileonly) {
                        width: 100%;
         
                     }

                    @include fontTitleBlack(3.5em, 1.2em, $wildcard-902);
                    @include breakpoint(tablet) {
                        width: 100%;
                        @include fontTitleBlack(2.5em, 1.2em, $wildcard-902);
                    }
                    @include breakpoint(phablet) {
                        @include fontTitleBlack(2.5em, 1.2em, $wildcard-902);
                    }

                    @include breakpoint(mobileonly) {
                        @include fontTitleBlack(2.5em, 1.4em, $wildcard-902);
                    }
                }
                @keyframes typewriter {
                    from {
                        width: 0%;  
                    }
                    to {
                        width: 60%;
                        height: 2.5em;
                    }
                }
                @keyframes blinking-cursor {
                    from{
                        border-color: transparent;
                    }
                    to {
                        border-right: 2px solid $wildcard-200;
                    }
                }
                p:nth-child(2) {
                    // margin-top: 20px;
                    width: 65%;
                    @include fontNormal(1.2em, 1.4em, $gray-700);

                    @include breakpoint(tablet) {
                        width: 100%;
                        @include fontNormal(1em, 1.4em, $gray-700);
                    }

                    @include breakpoint(phablet) {
                        width: 90%;
                        @include fontNormal(1em, 1.5em, $gray-700);
                    }

                    @include breakpoint(mobileonly) {
                        width: 95%;
                        @include fontNormal(1em, 1.4em, $gray-700);
                    }
                }
            }

            .bottom {
                margin-top: 50px;
                width: fit-content;
                @include rowflex(center,space-between);
                
                div:nth-child(2) {
                    margin-left: 20px;
                }
            }
        }

        &.right {
            width: fit-content;
            height: fit-content;

            img {
                width: 600px;

                @include breakpoint(tablet) {
                    width: 500px;
                }
                @include breakpoint(phablet) {
                    width: 400px;
                }

                @include breakpoint(mobileonly) {
                    width: 300px;
                    display: none;
                }
            }
        }

    }

    button.regular {
        outline: none;
        border: none;
        height: 50px;
        width: 200px;
        margin-bottom: 30px;
        text-align: center;
        background-color: $wildcard-700;
        @include fontMedium(1em, 0em, #fff);
        @include rowflex(center,center);
        transition: all 0.5s ease;
        &:hover {
            background-color: $wildcard-902;
            @include fontMedium(1em, 0em, #fff);
            cursor: pointer;
        }
      }
}