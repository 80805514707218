@import "../../styles/variables.scss";

.single-service-container {
    width: 100%;
    height: fit-content;

    @include breakpoint (mobileonly) {
       width: 90%;
    }

    &.no-reverse {
        @include rowflex(flex-start, space-between);


        @include breakpoint (mobileonly) {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        @include breakpoint (phablet) {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        // margin-bottom: 100px;

    }
    &.reverse {
        @include rowRevflex(flex-start, space-between);

        @include breakpoint (mobileonly) {
            // @include colflexx(flex-start);
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        @include breakpoint (phablet) {
            // @include colflexx(flex-start);
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }

    &.left {
        width: 55%;
        height: fit-content;
        @include colflex(flex-start,space-around);

        @include breakpoint(tablet) {
            width: 70%;
        }

        @include breakpoint(phablet) {
            width: 100%;
        }

        @include breakpoint (mobileonly) {
            width: 100%;
        }

        &.left-reverse {
            text-align: left;
            @include colflex(flex-end,space-between);
            width: 60%;


            @include breakpoint(mobileonly) {
                text-align: right;
                width:100%;
                @include colflex(flex-start,space-between);

            }
            @include breakpoint(phablet) {
                text-align: left;
                width:100%;
                @include colflex(flex-start,space-between);
            }


            p:nth-child(2) {
                text-align: right;
                @include fontNormal(1.5em, 1.5em, $gray-700);

                @include breakpoint(tablet) {
                    @include fontNormal(1em, 1.5em, $gray-700);
                }

                @include breakpoint(phablet) {
                    text-align: left;
                    @include fontNormal(.9em, 1.5em, $gray-700);
                }

                @include breakpoint(mobileonly) {
                    text-align: left;
                    @include fontNormal(.9em, 1.7em, $gray-700);
                }
            }
            
        }

        p:nth-child(1) {
            margin: 0;
            @include fontTitleBold(2.2em, 1em, $wildcard-902);

            @include breakpoint(tablet) {
                @include fontTitleBold(2em, 1em, $wildcard-902);
            }

            @include breakpoint(phablet) {
                @include fontTitleBold(1.5em, 1em, $wildcard-902);
            }

            @include breakpoint(mobileonly) {
                @include fontTitleBold(1.5em, 1em, $wildcard-902);
            }
        }
        p:nth-child(2) {
            width: 75%;
            @include fontNormal(1.5em, 1.5em, $gray-700);

            @include breakpoint(tablet) {
                width: 90%;
                @include fontNormal(1em, 1.5em, $gray-700);
            }
            @include breakpoint(phablet) {
                width: 100%;
                @include fontNormal(.9em, 1.7em, $gray-700);
            }
            @include breakpoint(mobileonly) {
                width: 100%;
                @include fontNormal(.9em, 1.7em, $gray-700);
            }
        }

        a {
            text-decoration: none;
            margin-top: 20px;
            transition: all 0.5s ease;
            @include fontTitleBold(2em, 1em, $wildcard-700);

            @include breakpoint(tablet) {
                margin-top: 10px;
                @include fontTitleBold(1.5em, 1em, $wildcard-700);
            }

            @include breakpoint(phablet) {
                @include fontTitleBold(1.2em, 1em, $wildcard-700);
            }

            &:hover {
                color: $wildcard-900;
            }
            
        }
    }

    &.right {
        width: fit-content;
        height: fit-content;

        img {
            width: auto;
            @include breakpoint(tablet) {
                width: 400px;
            }

            @include breakpoint(phablet) {
                width: 350px;
                margin-bottom: 50px;
            }

            @include breakpoint(mobileonly) {
                width: 250px;
                margin-bottom: 50px;
            }
        }
    }
}