@import "../../styles/variables.scss";

.tools-container {
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    background-color: #FFFFFF;
    @include rowflex(center,center);

    @include breakpoint(tablet) {
        height: 250px;
    }
    @include breakpoint(phablet) {
        height: 300px;
        margin-bottom: 50px;
    }

    @include breakpoint(mobileonly) {
        height: 350px;
    }

    .tools-inner-container {

        width: 80%;
        @include colflex(center,center);


        @include breakpoint(tablet) {
            width: 80%;
        }
        @include breakpoint(phablet) {
            width: 80%;
            height: 90%;
        }
        @include breakpoint(mobileonly) {
            width: 80%;
            height: 90%;
        }
        p {

           

            &.info {
                font-weight: 200;
                font-size: 1.5em;
                padding-bottom: 10px;
            }

            // margin: 0;
            @include fontTitleBold(3em, 0em, $wildcard-902);

            @include breakpoint(tablet) {
                @include fontTitleBold(2.5em, 0em, $wildcard-902);
            }

            @include breakpoint(phablet) {
                @include fontTitleBold(2em, 0em, $wildcard-902);
            }

            @include breakpoint(mobileonly) {
                margin: 0;
                @include fontTitleBold(2em, 1em, $wildcard-902);
            }
        }

        .info {
            font-weight: 400;
            font-size: 1.5em;
            padding-bottom: 10px;

            @include breakpoint(mobileonly) {
                width: 100% !important;
                text-align: center;
                padding: 0px !important;
                margin-top: 10px;
                margin-bottom: 30px;
                background-color: #FFFFFF;
                @include fontNormal(.9em, 1.7em, $wildcard-902);
            }
            @include breakpoint(phablet) {
                width: 100% !important;
                text-align: center;
                padding: 0px !important;
                margin-top: 10px;
                margin-bottom: 30px;
                background-color: #FFFFFF;
                @include fontNormal(.9em, 1.7em, $wildcard-902);
            }
        }
        p:nth-child(1) {
            margin: 0;
            @include fontTitleBlack(2.5em, 1em, $wildcard-902);
        }
        p:nth-child(2) {
            // margin: 0;
            width: 50%;
            text-align: center;
            @include fontLight(1.1em, 1.2em, $gray-700);
        }
        .brands {

            width: 100%;
            @include rowflex(center, space-between);

            @include breakpoint(mobileonly){
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                align-items: center;
                justify-content: center;
                row-gap: 10px;
                column-gap: 10px;
                width: 90%;
                // display: flex;
                // flex-wrap: wrap;
                // @include rowflex(center, center);
                // row-gap: 10px;
                // column-gap: 30px;

                img:not(:first-child){
                    margin-left: 10px;
                }

                img:nth-child(4){
                    width: 50px !important;
                }
            }
            @include breakpoint(phablet){
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                align-items: center;
                justify-content: center;
                row-gap: 10px;
                column-gap: 10px;
                width: 85%;
                // display: flex;
                // flex-wrap: wrap;
                // @include rowflex(center, center);
                // row-gap: 10px;
                // column-gap: 30px;

                img:not(:first-child){
                    margin-left: 10px;
                }

                img:nth-child(4){
                    width: 50px !important;
                }
            }


            width: 80%;
            margin-top: 30px;
            @include rowflex(center,center);
            flex-wrap: wrap;
            gap: 20px;

            img {
                // width: auto;
                // max-width: 250px;
                // height: auto;
                // max-height: 80px;
                width: 120px;

                @include breakpoint(tablet) {
                    width: 150px;
                }

                @include breakpoint(phablet) {
                    width: 120px;
                }

                @include breakpoint(mobileonly) {
                    width: 70px !important;
                    margin-top: 10px;
                }
            }
            img:not(:first-child) {
                margin-right: 30px;
            }
        }
    }
}